document.addEventListener("turbolinks:load", () => {

	// Auto submit filter and process year context
	var initiativeSelect = document.getElementById('initiatve-year-select');
	var initiativeForm = document.getElementById('initiatve-year-form');
	var queryString = window.location.search;
	var urlParams = new URLSearchParams(queryString);
	var year = urlParams.get('year');

	initiativeSelect.addEventListener('change', function(event) {
		initiativeForm.submit();
	});

	if ( year != null ) {
		initiativeSelect.value = year
	} else {
		current_year = new Date().getFullYear();
		initiativeSelect.value = current_year
	}

	// When New button is clicked
	var newButton = document.getElementById('new-initiative-button');

	newButton.addEventListener('click', function(event) {
		year = newButton.dataset.year;
		setYearSelects(year);
	});

	function setYearSelects(year) {
		startYear = document.getElementById('initiative_start_year');
		endYear = document.getElementById('initiative_end_year');

		startYear.value = year
		endYear.value = parseInt(year) + 1
	}

});