import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['nav']

  toggle(event) {
    event.preventDefault()
    this.navTarget.classList.toggle('none')
  }

  close(event) {
    if (this.element.contains(event.target) == false) {
      this.navTarget.classList.add('none')
    }
  }

}