document.addEventListener("turbolinks:load", () => {

	function init() {
		const modalOpen = document.querySelectorAll('.modal-open');
		const modalClose = document.querySelectorAll('.modal-close');

		modalOpen.forEach(function(open) {
			open.addEventListener('click', function(event) {
				event.preventDefault();
				let target = this.dataset.target;
				openModal(target);
				toggleOverlay();
			});
		});

		modalClose.forEach(function(close) {
			close.addEventListener('click', function(event) {
				event.preventDefault();
				let modal = this.closest('.modal');
				modal.classList.remove('active');
				toggleOverlay();
			});
		});
		
	}

	function openModal(target) {
		let modal = document.getElementById(target);
		modal.classList.add('active');
	}

	function toggleOverlay() {
		let overlay = document.querySelector('.overlay');
		if ( overlay.classList.contains('active') ) {
			overlay.classList.remove('active');
		} else {
			overlay.classList.add('active');
		}
	}

	init();

});