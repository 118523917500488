document.addEventListener("turbolinks:load", () => {

	// User Menu Toggle
	const userMenuToggle = document.querySelector('.user-menu-toggle');
	const userMenu = document.querySelector('.user-menu');

	userMenuToggle.addEventListener('click', function(event) {
		event.preventDefault();
		userMenu.classList.toggle('active');
	});

	// Mobile Nav Toggle
	const mobileNavToggle = document.querySelector('.menu-toggle');
	const mobileNavClose = document.querySelector('.mobile-nav-close');
	const mobileNav = document.querySelector('.mobile-nav-container');

	mobileNavToggle.addEventListener('click', function(event) {
		event.preventDefault();
		mobileNav.classList.add('active');
	});

	mobileNavClose.addEventListener('click', function(event) {
		event.preventDefault();
		mobileNav.classList.remove('active');
	});

	// Generic Toggle
	const toggleLink = document.querySelector('.toggle-link');

	if ( toggleLink != null ) {

		toggleLink.addEventListener('click', function(event) {
			event.preventDefault();

			let target = this.getAttribute('href');

			this.classList.toggle('active');
			document.querySelector(target).classList.toggle('active');
		});

	}

	// Refresh company stats page when changing report type
	const reports_select = document.getElementById('report_type');
	const reports_submit = document.getElementById('company-stats-submit');

	if ( reports_select != null ) {
		
		reports_select.addEventListener('change', function(event) {
			reports_submit.click();
		});

	}

});