document.addEventListener("turbolinks:load", () => {
	const big3Select = document.querySelector('.populate-big3');

	big3Select.addEventListener('change', function(event) {
		let big3_id = big3Select.options[big3Select.selectedIndex].value
		getData(big3_id);
	});

	function getData(big3_id) {
		fetch('/get_big3?big3_id=' + big3_id)
			.then(function(response) {
				response.json().then(function(data) {
					updateFields(data);
				});
			}
		);
	}

	function updateFields(data) {

			// If we have data
			let nameField = document.getElementById('user_big3_name')
			let descriptionField = document.getElementById('user_big3_description')
			let successField = document.getElementById('user_big3_success_measures')
			nameField.value = data[0].name
			descriptionField.value = data[1].description.body
			successField.value = data[1].success_measures.body
	}

});