import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['progress', 'progressBar', 'stepsRemaining', 'container']
  static values = {
    url: String
  }

  connect() {
    const container = this.containerTarget
    const display = getCookie('getting_started_display')

    if (display == 'hide') {
      // Hide the container
      container.classList.add('none')
    } else {
      // Show the container
      container.classList.remove('none')
    }
  }

  complete(event) {
    event.preventDefault()

    // Set Variables
    const checkbox = event.target
    const step = checkbox.dataset.step
    const is_complete = checkbox.classList.contains('completed')
    const completed_status = is_complete ? false : true
    const progress = is_complete ? parseInt(this.progressTarget.textContent) - 20 : parseInt(this.progressTarget.textContent) + 20
    const steps_remaining = this.stepsRemainingTarget.textContent
    const token = document.getElementsByTagName('meta')["csrf-token"].content

    if (checkbox.classList.contains('completed')) {
      // Unmark the todo as completed
      checkbox.classList.remove('completed')

      // Number in Your Setup button
      this.stepsRemainingTarget.textContent = parseInt(steps_remaining) + 1
    } else {
      // Mark the todo as completed
      checkbox.classList.add('completed')

      // Number in Your Setup button
      this.stepsRemainingTarget.textContent = parseInt(steps_remaining) - 1
    }

    // Update the column in the database
    fetch(this.urlValue, {
      method: 'PATCH',
      body: JSON.stringify({
        step: step,
        completed: completed_status
      }),
      headers: { "Content-type": "application/json; charset = UTF-8", 'X-CSRF-Token': token}
    })
    .then(response => response.json())
    .then(json => console.log(json))

    // Update the front-end

      // Progress bar
      this.progressTarget.textContent = progress + '%'
      this.progressBarTarget.style.width = progress + '%'

      // Handle completion of all steps
      if ( this.stepsRemainingTarget.textContent == '0' ) {
        // Change text
        let title = document.querySelector('.getting-started-title h4')
        title.textContent = 'Congratulations'

        // Hide after 5 seconds
        setTimeout(() => {
          this.containerTarget.parentElement.style.display = 'none'
        }, 5000);

        // Update the company onboarding_step in the database
        fetch(this.urlValue, {
          method: 'PATCH',
          body: JSON.stringify({
            step: 'complete'
          }),
          headers: { "Content-type": "application/json; charset = UTF-8", 'X-CSRF-Token': token }
        })
        .then(response => response.json())
        .then(json => console.log(json))
      }
  }

  toggle(event) {
    event.preventDefault()
    const container = this.containerTarget

    toggle_container(container)
  }

  dismiss(event) {
    event.preventDefault()

    let confirmDialog = confirm('Are you sure you want to permanently dismiss your setup guide?')
    const token = document.getElementsByTagName('meta')["csrf-token"].content

    if (confirmDialog == true) {
      // Update the front-end
      this.containerTarget.parentElement.style.display = 'none'

      // Update the company onboarding_step in the database
      fetch(this.urlValue, {
        method: 'PATCH',
        body: JSON.stringify({
          step: 'complete'
        }),
        headers: { "Content-type": "application/json; charset = UTF-8", 'X-CSRF-Token': token }
      })
      .then(response => response.json())
      .then(json => console.log(json))
    }
  }

} 

function toggle_container(container, set_cookie) {
  // Check the cookie, if it is set hide the container else show display the container
  const display = getCookie('getting_started_display')

  if ( display == 'hide' ) {
    // Show the container
    container.classList.remove('none')

    // Clear the cookie
    document.cookie = "getting_started_display=; expires=Thu, 18 Dec 2030 12:00:00 UTC; path=/";
  } else {
    // Hide the container
    container.classList.add('none')

    // Set the cookie
    document.cookie = "getting_started_display=hide; expires=Thu, 18 Dec 2030 12:00:00 UTC; path=/";
  }
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}