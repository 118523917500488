import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['passwordInput', 'submit', 'errorMessage', 'requiredCheck', 'requiredStatus']
  static values = {
    id: String,
  }

  connect() {
    const status = this.requiredStatusTarget.checked
    const fields = this.requiredCheckTargets

    if (status) {
      updateRequiredState(false, fields, 'none')
    } else {
      updateRequiredState(true, fields, 'block')
    }
  }

  password_length() {
    const input = this.passwordInputTarget
    const message = this.errorMessageTarget

    if (input.value.length < 5) {
      // If the password does not meet the min 6 character requirement...
      // Show error message
      message.classList.remove('none')

      // Disable submit button
      this.submitTarget.disabled = true
    } else {
      // If the password does meet the min 6 character requirement...
      // Remove error message
      message.classList.add('none')

      // Enable submit button
      this.submitTarget.disabled = false
    }
  }

  toggle_required() {
    const status = this.requiredStatusTarget.checked
    const fields = this.requiredCheckTargets

    if (status) {
      updateRequiredState(false, fields, 'none')
    } else {
      updateRequiredState(true, fields, 'block')
    }
  }

  validate_required_fields(event) {
    event.preventDefault()
    const form = document.getElementById(this.idValue)
    let errorCount = 0
    const errorDisplay = document.querySelector('#' + this.idValue + ' .form-errors.generic')

    // Go through each required input and make sure it has a value
    const required_inputs = form.querySelectorAll('[data-required="true"]')

    required_inputs.forEach(function (field) {
      if ((field.value === '' || field.value == 'nil') && !field.classList.contains('trix-input')) {
        // Add error to parent container
        field.parentElement.classList.add('error')
        errorCount++
      }  else {
        // Remove error from parent container
        field.parentElement.classList.remove('error')
      }
    })

    if (errorCount > 0) {
      // If there are errors display them
      errorDisplay.classList.remove('none')
    } else {
      // If there are no errors submit the form
      form.submit()
    }
  }

}

function updateRequiredState(status, fields, display) {
  fields.forEach(function (field) {
    field.dataset.required = status
    field.parentElement.style.display = display
  })
}