document.addEventListener("turbolinks:load", () => {
	submitTriggers = document.querySelectorAll('.submit-form');

	submitTriggers.forEach(function(trigger) {
		trigger.addEventListener('change', function(event) {
			target = trigger.dataset.form;
			form = document.getElementById(target);
			form.submit();
		});
	});

});