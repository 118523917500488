import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['target', 'secondTarget', 'thirdTarget', 'year', 'existingYear', 'existingInitiative', 'existingAnnual', 'existingQuarterly']
  static values = {
    url: String,
    secondUrl: String,
    thirdUrl: String
  }

  connect() {
    if (this.hasExistingYearTarget) {
      this.yearTarget.value = this.existingYearTarget.value

      // Update and set all selects
      updateInitiativeSelect(this.urlValue, this.existingYearTarget.value, this.targetTarget, this.existingInitiativeTarget.value)
      updateAnnualPrioritySelect(this.secondUrlValue, this.existingInitiativeTarget.value, this.secondTargetTarget, this.existingYearTarget.value, this.existingAnnualTarget.value)
      updateQuarterlyPrioritySelect(this.thirdUrlValue, this.existingAnnualTarget.value, this.thirdTargetTarget, this.existingQuarterlyTarget.value)
    }
  }
  
  updateInitiatives(event) {
    const year = event.target.options[event.target.selectedIndex].value
    const target = this.targetTarget
    const url = this.urlValue

    updateInitiativeSelect(url, year, target, null)
    this.secondTargetTarget.value = null
    this.thirdTargetTarget.value = null
  }

  updateAnnualPriorities(event) {
    const initiative_id = event.target.options[event.target.selectedIndex].value
    const target = this.secondTargetTarget
    const url = this.secondUrlValue
    const year = this.yearTarget.options[this.yearTarget.selectedIndex].value

    updateAnnualPrioritySelect(url, initiative_id, target, year, null)
    this.thirdTargetTarget.value = null
  }

  updateQuarterlyPriorities(event) {
    const annual_priority_id = event.target.options[event.target.selectedIndex].value
    const target = this.thirdTargetTarget
    const url = this.thirdUrlValue

    updateQuarterlyPrioritySelect(url, annual_priority_id, target, null)
  }

}

function updateInitiativeSelect(url, year, target, selected) {
  fetch(url + '?year=' + year)
    .then(function (response) {
      response.json().then(function (data) {
        processSelect(data, target, selected);
      });
    }
    );
}

function updateAnnualPrioritySelect(url, initiative_id, target, year, selected) {
  fetch(url + '?initiative_id=' + initiative_id + '&year=' + year)
    .then(function (response) {
      response.json().then(function (data) {
        processSelect(data, target, selected);
      });
    }
    );
}

function updateQuarterlyPrioritySelect(url, annual_priority_id, target, selected) {
  fetch(url + '?annual_priority_id=' + annual_priority_id + '&selected=' + selected)
    .then(function (response) {
      response.json().then(function (data) {
        processQuarterlySelect(data, target, selected);
      });
    }
    );
}

function processSelect(data, target, selected) {
  if (data.length === 0) {
    // If we don't have any options reset the select and disable it
    target.disabled = true;
    target.innerHTML = '<option value="nil">No results for that selection</option>';
  } else {
    // If we have options build them out
    let options = '<option value="nil">Select</option>';
    data.forEach(function (option) {
      let selectedValue = selected == option.id ? 'selected' : ''
      options += '<option value="' + option.id + '" ' + selectedValue + '>' + option.name + '</option>';
    });

    // Enable the select and populate the new fields
    target.innerHTML = options;
    target.disabled = false;
  }
}

function processQuarterlySelect(data, target, selected) {
  if (data.length === 0) {
    // If we don't have any options reset the select and disable it
    target.disabled = true;
    target.innerHTML = '<option value="nil">No results for that selection</option>';
  } else {
    // If we have options build them out
    let options = '<option value="nil">Select</option>';
    data.forEach(function (option) {
      let selectedValue = selected == option.id ? 'selected' : ''
      options += '<option value="' + option.id + '" ' + selectedValue + '>Q' + option.quarter + ' - ' + option.name + '</option>';
    });

    // Enable the select and populate the new fields
    target.innerHTML = options;
    target.disabled = false;
  }
}