import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['field', 'text', 'submit', 'status']

  connect() {
  }

  requiredCheck(event) {
    const status = event.target.options[event.target.selectedIndex].value
    const field = this.fieldTarget
    const text = this.textTarget
    const submit = this.submitTarget

    if (status == 'help' || status == 'bad' ) {
      field.classList.add('required')
      text.classList.remove('none')
      submit.disabled = true
    } else {
      field.classList.remove('required')
      text.classList.add('none')
      submit.disabled = false
    }
  }

  requirementMet(event) {
    const status = this.statusTarget.options[this.statusTarget.selectedIndex].value
    const field = this.fieldTarget
    const text = this.textTarget
    const submit = this.submitTarget

    if (field.value.length > 0 && status == 'help' || status == 'bad') {
      field.classList.remove('required')
      text.classList.add('none')
      submit.disabled = false
    }
  }

}