document.addEventListener("turbolinks:load", () => {
	var frequencySelect = document.querySelectorAll('.reporting-frequency-select');
	var biWeeklyOptions = [['1st and 3rd', '1-3'], ['2nd and 4th', '2-4']];
	var weeklyOptions = [['1st', 1], ['2nd', 2], ['3rd', 3], ['4th', 4]];

	if ( frequencySelect != null ) {

		frequencySelect.forEach(function(select) {
			var type = select.dataset.type;
			frequency = select.options[select.selectedIndex].value;
			weekSelected = select.dataset.week_selected;

			if ( frequency != null && weekSelected != null ) {
				updateWeeklyOptions(type, frequency, weekSelected);
			}

			select.addEventListener('change', function() {
				frequency = select.options[select.selectedIndex].value;
				updateWeeklyOptions(type, frequency);
			});
		});

	}

	function updateWeeklyOptions(type, frequency, selected) {
		weekSelect = document.querySelector('.reporting-weeks[data-type=' + type + '] select');
		if (frequency == 'Twice a Month' ) {
			options = buildOptions(biWeeklyOptions, selected);
			weekSelect.innerHTML = options;
			weekSelect.disabled = false;
		} else if ( frequency == 'Monthly' ) {
			options = buildOptions(weeklyOptions, selected);
			weekSelect.innerHTML = options;
			weekSelect.disabled = false;
		} else {
			options = buildOptions(null, null);
			weekSelect.innerHTML = options;
			weekSelect.disabled = true;
		}
	}

	function buildOptions(data, selected) {
		options = '<option>Select</option>';

		if ( data != null ) {
			data.forEach(function(option) {
				if ( option[1] == selected ) {
					options += '<option value="' + option[1] + '" selected>' + option[0] + '</option>';
				} else {
					options += '<option value="' + option[1] + '">' + option[0] + '</option>';
				}
			});
		} else {
			options = '<option>N/A</option>';
		}

		return options;
	}

});
