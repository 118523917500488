import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['toggleLink']
  static values = {
    target: String
  }

  connect() {
    // Grab the anchor params from the URL
    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)
    let anchor = params.get('anchor')

    // Find the anchor and toggle it and all its parents
    if (anchor) {
      let container = document.getElementById(anchor)
      let initiative = document.querySelector('.accordion-toggle')
      let y = container.getBoundingClientRect().top + window.pageYOffset;

      // Set top level initiative to active
      initiative.classList.add('active')
      
      // Go up the DOM tree and toggle all parents to show and be active
      let parent = container.parentElement.closest('.drilldown-container')
      while (parent) {
        parent.classList.remove('none')
        parent.querySelector('.accordion-toggle').classList.add('active')
        console.log(parent)
        parent = parent.parentElement.closest('.drilldown-container')
      }

      // Show the anchor container
      container.classList.remove('none')

      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  toggle(event) {
    event.preventDefault()
    let container = document.getElementById(this.targetValue)
    container.classList.toggle('none')
    this.toggleLinkTarget.classList.toggle('active')
  }

}