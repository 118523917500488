document.addEventListener("turbolinks:load", () => {

	const userSearch = document.querySelector('.user-search input');
	const users = document.querySelectorAll('.list-user');

	userSearch.addEventListener('keyup', function(event) {
		filterUsers();
	});

	function filterUsers() {

		// Get value of serach input
		var query = userSearch.value;

		// Require at least 2 characters before filtering usrs list
		if ( query.length > 1 ) {
			users.forEach(function(user) {
				showHideUser(user, query);
			});
		} else {
			users.forEach(function(user) {
				user.classList.remove('none');
			});
		}

	}

	function showHideUser(user, query) {
	
		// Get the name of the user	
		var name = user.querySelector('.name').textContent.toLowerCase();
		var query = query.toLowerCase();

		// If the name does not contain the query hide it
		if ( name.includes(query) ) {
			user.classList.remove('none');
		} else {
			user.classList.add('none');
		}

	}

});