import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['q1', 'q2', 'q3', 'q4', 'allQuarters']

  filter(event) {
    let quarter = event.target.options[event.target.selectedIndex].value
    let all = this.allQuartersTargets

    if (quarter == '*') {
      all.forEach(function(e) {
        e.classList.remove('none')
      });
    } else if (quarter == '1') {
      update_classes(all, this.q1Targets)
    } else if (quarter == '2') {
      update_classes(all, this.q2Targets)
    } else if (quarter == '3') {
      update_classes(all, this.q3Targets)
    } else if (quarter == '4') {
      update_classes(all, this.q4Targets)
    }
  }

}

function update_classes(all, group) {
  all.forEach(function(e) {
    e.classList.add('none')
  });
  group.forEach(function(e) {
    e.classList.remove('none')
  });
}