document.addEventListener("turbolinks:load", () => {
  const reportFilterForm = document.getElementById('report-filter-form');
  const yearSelect = reportFilterForm.querySelector("[name='year']");
  const quarterSelect = reportFilterForm.querySelector("[name='quarter']");

  yearSelect.addEventListener('change', (event) => {
    selectedYear = event.target.options[event.target.selectedIndex].value

    const today = new Date();
    const currentYear = today.getFullYear()
    const currentQuarter = Math.floor((today.getMonth() + 3) / 3)

    let options = [];
    if (currentYear.toString() === selectedYear) {
      options = Array(currentQuarter).fill(1).map((x, y) => x + y)
    } else {
      options = ["1", "2", "3", "4"];
    }

    // already selected quarter
    const oldQuarterValue = quarterSelect.options[quarterSelect.selectedIndex].value;

    // clear current options
    quarterSelect.options.length = 0;

    quarterSelect.options[quarterSelect.options.length] = new Option("All", "");

    for(index in options) {
      quarterSelect.options[quarterSelect.options.length] = new Option(options[index], parseInt(index) + 1);
    }

    if (oldQuarterValue === "") {
      // the All option
      return
    } else if (parseInt(oldQuarterValue) > parseInt(currentQuarter)) {
      // they selected a quarter after this years current one
      quarterSelect.value = currentQuarter
    } else {
      quarterSelect.value = oldQuarterValue
    }
  })
})
